import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import { graphql } from "gatsby"
import '../styles/global.css';

export default ({ data }) => {
  return (
    <main>
      <Helmet>
        <title>Blog | {data.site.siteMetadata.title}</title>
      </Helmet>
      <Header data={data} />
      <div class="content flex">
        <div class="left">
          <h1>Blog</h1>
          <p class="subheadline">Find all my blog articles, guides, recommendations and private thoughts here.</p>
          <p><i>Nothing here yet.</i></p>
        </div>
      </div>
    </main>
  );
}

export const query = graphql`
query {
  profilePic: file(relativePath: { eq: "nils.png" }) {
    childImageSharp {
      fluid(maxWidth: 200) {
        ...GatsbyImageSharpFluid
      }
    }
  },
  site {
    siteMetadata {
      title
    }
  }
}
`